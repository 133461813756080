export const environment = {
  production: true,
  languages: ['he'],
 
    //new-dev
    // iclLabsUrl: 'https://api.icl-laboratories-qa.appsc.icldig.icl-group.com/api',
     //new-prod
     iclLabsUrl: 'https://api.icl-laboratories.appsb.icldig.icl-group.com/api',
   adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
    //  prod
     clientId: '0ee20d51-e507-4411-b9b4-e30f03f3634a',
    // clientId: '57c53176-a9bd-4129-8ce7-d094dd145ca9',
    expireOffsetSeconds: 320,//15 minutes (60-15 = 45 minutes auth)
   
        //new-dev
        // redirectUri: 'https://icl-laboratories-qa.appsc.icldig.icl-group.com/auth/postLogin',
        //new-prod
        redirectUri: 'https://icl-laboratories.appsb.icldig.icl-group.com/auth/postLogin',
    
    popUp: false,
    cacheLocation: 'localStorage',
   resource: 'https://graph.microsoft.com',
  },
  firebaseConfig : {
    apiKey: "AIzaSyD3y2bZgegpbwaiXP-gzVp8BY_ATny9Gm4",
    authDomain: "icl-laboratories.firebaseapp.com",
    projectId: "icl-laboratories",
    storageBucket: "icl-laboratories.appspot.com",
    messagingSenderId: "585454940250",
    appId: "1:585454940250:web:732d7193c1f5107348e863",
    measurementId: "G-VQ5HGLZK89"
  }
};
