import { BrowserModule } from '@angular/platform-browser';
import { Component, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderModule } from './globals/components/loader/loader.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import  firebase from 'firebase/app'; 
import "firebase/analytics";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SamplesTableComponent } from './modules/icl-labs/routes/samples-table/samples-table.component';
import { RulerComponent } from './globals/components/ruler/ruler.component';
import { MatterComponent } from './globals/components/matter/matter.component';
import { MatterCreateComponent } from './globals/components/matter-create/matter-create.component';

// 


@Component({
  selector: 'app-root',
  template: `
    <div dir="{{ 'cssDirection' | translate }}" class="{{ 'cssDirection' | translate }}">
      <router-outlet></router-outlet>
    </div>
    <global-loader></global-loader>
  `,
  styles: ['.rtl { direction: rtl; text-align: right; }']
})

export class AppComponent {

  constructor(public translate: TranslateService) {
    let lang = localStorage.getItem('lang');

    if (environment.languages.indexOf(lang) < 0) {
      lang = environment.languages[0];
      localStorage.setItem('lang', lang);
    }
    translate.setDefaultLang(lang);
    const app = firebase.initializeApp(environment.firebaseConfig);
    const analytics = firebase.analytics(app);

  }
};

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    // AngularFireModule.initializeApp(environment.firebaseConfig),
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
 	  AngularFirestoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoaderModule
  ],
  bootstrap: [AppComponent],
  providers: [SamplesTableComponent,RulerComponent,MatterComponent,MatterCreateComponent]

})
export class AppModule { }
